<template>
  <div class="procureDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.procure.id ? '编辑' : '新增' }}采购明细（到达）</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="info" @click="data.procure = {}">清空</el-button>
          <el-button type="primary" @click="save()">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="发货日期">
            <el-date-picker style="width: 100%;" v-model="data.procure.sendDate" type="date" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="到货日期">
            <el-date-picker style="width: 100%;" v-model="data.procure.receiveDate" type="date" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="供应商">
            <el-select v-model="data.procure.supplier" filterable disabled>
              <el-option v-for='supplierItem in show.supplierList' :key="supplierItem.id" :label="supplierItem.value" :value="supplierItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="仓储">
            <el-select v-model="data.procure.storage" filterable disabled @change="findTransDedProcureFormula()">
              <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="运输公司">
            <el-select v-model="data.procure.transportCompany" filterable disabled>
              <el-option v-for='transportCompanyItem in show.transportCompanyList' :key="transportCompanyItem.id" :label="transportCompanyItem.value" :value="transportCompanyItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车号">
            <!-- <el-input v-model="data.procure.truckNumber" :max="100" /> -->
            <el-autocomplete style="width: 100%;" v-model="data.procure.truckNumber" :fetch-suggestions="getTruckNumbers" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="煤矿品名">
            <el-select v-model="data.procure.product" filterable disabled>
              <el-option v-for='productItem in show.productList' :key="productItem.id" :label="productItem.value" :value="productItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="规格">
            <el-select v-model="data.procure.productSpecs" filterable disabled>
              <el-option v-for='productSpecsItem in show.productSpecsList' :key="productSpecsItem.id" :label="productSpecsItem.value" :value="productSpecsItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注">
            <el-input v-model="data.procure.note" :max="100" />
          </el-form-item>
        </el-col>
        <el-divider content-position="left">数量</el-divider>
        <el-col :span="12">
          <el-form-item label="发货数量">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procure.sendQuantity" @change="computeProductAmount()" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="到货重车">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procure.receiveQuantityGross" @change="computeReceiveQuantity(); computeTransportAmount()" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="到货皮重">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procure.receiveQuantityTare" @change="computeReceiveQuantity(); computeTransportAmount()" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="到货数量">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procure.receiveQuantity" @change="computeTransportAmount()" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="亏吨">
            <el-text v-if="data.procure.sendQuantity != null && data.procure.receiveQuantity != null">
              {{ math.plus(data.procure.sendQuantity, -data.procure.receiveQuantity) }}
            </el-text>
          </el-form-item>
        </el-col>
        <el-divider content-position="left">运费</el-divider>
        <el-col :span="8">
          <el-form-item label="运输单价">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procure.transportPrice" @change="computeTransportAmount()" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="运费扣除">
            <el-input-number style="width: calc(100% - 70px)" :controls="false" :precision="2" v-model="data.procure.transportDeductionAmount" @change="computeTransportAmount()" />
            <el-link type="primary" style="margin-left: 20px;" @click="show.transDedAmountFormulaDialog = true">公式</el-link>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="实付运费">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procure.transportAmount" />
          </el-form-item>
        </el-col>
        <el-divider content-position="left">货款</el-divider>
        <el-col :span="12">
          <el-form-item label="煤矿单价">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procure.productPrice" @change="computeProductAmount()" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="实付煤矿费用">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procure.productAmount" disabled />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>

  <el-dialog v-model="show.transDedAmountFormulaDialog" title="选择运费扣除公式" width="650">
    <el-table :data="show.transDedAmountFormulaList" :show-header="false">
      <el-table-column prop="name">
        <template #default="scope">
          <el-text v-show="!scope.row.default">{{ scope.row.name }}</el-text>
          <el-text v-show="scope.row.default" type="primary">{{ '【默认】' + scope.row.name }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="btn" width="200">
        <template #default="scope">
          <el-button-group>
            <el-button type="primary" @click="useTransDedAmountFormula(scope.row)">使用</el-button>
            <el-button @click="useTransDedAmountFormulaAndSetDefault(scope.row)">并标记默认</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script setup>
import { reactive, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const show = reactive({
  supplierList: [],
  storageList: [],
  productList: [],
  productSpecsList: [],
  transportCompanyList: [],
  transDedAmountFormulaList: [],
  transDedAmountFormulaDialog: false
})
const pageParam = cache.getObject(cache.keys.pageParam + "procureDetail")
const data = reactive({
  procure: pageParam ? JSON.parse(JSON.stringify(pageParam)) : {},
})

api.get('/backend/dict/getByCode', { params: { code: 'supplier' } }).then(res => {
  show.supplierList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'product' } }).then(res => {
  show.productList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'productSpecs' } }).then(res => {
  show.productSpecsList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'transportCompany' } }).then(res => {
  show.transportCompanyList = res.dictList
})

const findTransDedProcureFormula = () => {
  const formulaParam = {
    model: 'transDedProcure'
  }
  if (data.procure.storage) {
    formulaParam.defaultKey = data.procure.storage
  }
  api.get('/backend/formula/get', { params: formulaParam }).then(res => {
    show.transDedAmountFormulaList = res.list
    if (res.data && res.data.default) {
      for (let formula of show.transDedAmountFormulaList) {
        if (formula.id == res.data.default.id) {
          formula.default = true
        }
      }
    }
  })
}
findTransDedProcureFormula()

const save = () => {
  const param = JSON.parse(JSON.stringify(data.procure))
  param.sendDate = util.parseTime(param.sendDate, '{y}-{m}-{d}')
  param.receiveDate = util.parseTime(param.receiveDate, '{y}-{m}-{d}')
  if (!param.receiveQuantityGross) {
    param.receiveQuantityGross = 0
  }
  if (!param.receiveQuantityTare) {
    param.receiveQuantityTare = 0
  }
  param.hasCheckTransportAmount = null
  api.post('/backend/procure/receive', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

const useTransDedAmountFormulaAndSetDefault = (formula) => {
  if (!data.procure.storage) {
    ElMessage.error('没有选择仓库，无法设置默认公式')
    return
  }
  api.post('/backend/formula/setDefault', {
    model: 'transDedProcure',
    key: data.procure.storage,
    formulaId: formula.id
  }).then(() => {
    ElMessage.success('公式已设为' + data.procure.storage + '默认')
    useTransDedAmountFormula(formula)
  })
}

const useTransDedAmountFormula = (formula) => {
  try {
    let 结果 = null
    const 发货数量 = data.procure.sendQuantity
    const 到货数量 = data.procure.receiveQuantity
    console.info('发货数量', 发货数量, '到货数量', 到货数量)
    eval(formula.jsScript)
    if (结果 === null) {
      ElMessage.error('公式有误，请手动填写')
      return
    }
    data.procure.transportDeductionAmount = math.halfUp(结果, 2)
    computeTransportAmount()
    show.transDedAmountFormulaDialog = false
  } catch {
    ElMessage.error('公式有误，请手动填写')
  }
}

const computeReceiveQuantity = () => {
  let receiveQuantityGross = data.procure.receiveQuantityGross
  let receiveQuantityTare = data.procure.receiveQuantityTare
  if (!receiveQuantityGross) {
    receiveQuantityGross = 0
  }
  if (!receiveQuantityTare) {
    receiveQuantityTare = 0
  }
  data.procure.receiveQuantity = math.plus(receiveQuantityGross, -receiveQuantityTare)
}

const computeProductAmount = () => {
  if (data.procure.sendQuantity && data.procure.productPrice) {
    let productAmount = math.multiply(data.procure.sendQuantity, data.procure.productPrice)
    if (data.procure.productAmount != productAmount) {
      data.procure.productAmount = productAmount
      ElMessage.success('货款已自动更新')
    }
  }
}

const computeTransportAmount = () => {
  if (data.procure.receiveQuantity && data.procure.transportPrice) {
    let transportAmount = math.plus(math.multiply(data.procure.receiveQuantity, data.procure.transportPrice), -(data.procure.transportDeductionAmount || 0))
    transportAmount = math.floor(transportAmount, -1)
    if (data.procure.transportAmount != transportAmount) {
      data.procure.transportAmount = transportAmount
      ElMessage.success('运费已自动更新（自动抹零）')
    }
  }
}

const getTruckNumbers = (queryString, callback) => {
  api.get('/backend/procure/getTruckNumbers', { params: { truckNumberLike: queryString } }).then(res => {
    callback(res.list.map(item => { return { value: item } }))
  })
}

// 关闭页面生命周期
onUnmounted(() => {
  if (!data.procure.id) {
    if (JSON.stringify(data.procure) == '{}') {
      cache.setObject(cache.keys.pageCache + "procureDetail", null)
    } else {
      cache.setObject(cache.keys.pageCache + "procureDetail", data.procure)
    }
  }
})
if (!data.procure.id) {
  const procure = cache.getObject(cache.keys.pageCache + "procureDetail")
  if (procure) {
    data.procure = procure
    ElMessage.success('已快捷填写上次填写的信息')
  }
}
</script>

<style lang="less">
.procureDetail {
  margin: auto;
  max-width: 900px;
}
</style>